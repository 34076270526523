.MuiTableCell-root {
  cursor: pointer;
}

.MuiButton-containedPrimary {
  background-color: #6b8263 !important;
}

.custom-datepicker {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-datepicker:hover {
  border-color: rgba(0, 0, 0, 0.5);
}

.custom-datepicker:focus-within {
  border-color: #1976d2; /* MUI primary blue */
  box-shadow: 0 0 4px rgba(25, 118, 210, 0.5);
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  border: none !important;
  outline: none !important;
}

.react-date-picker__inputGroup {
  display: flex;
  gap: 4px;
}

.react-date-picker__inputGroup__input {
  border: none;
  outline: none;
  width: 40px !important;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.react-date-picker__inputGroup__input:focus {
  outline: none;
}

.react-date-picker__calendar-button {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  margin-left: 8px;
}

.react-date-picker__calendar-button__icon {
  stroke: rgba(0, 0, 0, 0.6);
  transition: stroke 0.2s ease-in-out;
}

.react-date-picker__calendar-button:hover
  .react-date-picker__calendar-button__icon {
  stroke: #1976d2;
}

.react-date-picker__inputGroup__divider {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
